<script setup lang="ts">
  import type { AuthRole } from '~/utils/auth'

  const props = defineProps<{ roles: AuthRole[] }>()
  const authStore = useAuthStore()
</script>

<template>
  <template v-if="authStore.isInRoles(props.roles)">
    <slot />
  </template>
</template>
